<template>
  <div class="row p-1" id="printableArea" style="color: #00364f">
    <div class="col-xl-12 col-md-12 col-lg-12 col-12" id="invoice">
      <div>
        <div>
          <table style="width: 100%; text-transform: capitalize" class="font">
            <tr>
              <th class="alin-left">
                <strong class="heading" style="font-size: 14pt" v-if="userData"
                  >{{ userData.name }}
                </strong>
              </th>
              <th class="alin-right"><strong class="heading">INVOICE</strong></th>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>Address: </strong
                  ><span v-if="userData">{{ userData.address }}</span>
                </div>
              </td>
              <td class="alin-right">
                <div style="font-size: 10pt; color: #00364f">
                  <strong># {{ invoiceData.clientServiceNo }}</strong>
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[M]: </strong
                  ><span v-if="userData"> +91-{{ userData.phone }}, </span>
                </div>
              </td>
              <td class="alin-right">
                <div style="font-size: 10pt">
                  <!-- <strong># INV-1011-22</strong> -->
                  <button class="btn-paid" v-if="dueAmt == 0"><i>Paid</i></button>
                  <button class="btn-paid" style="background-color: #f21000" v-else>
                    <i>Due</i>
                  </button>
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[E]: </strong>
                  <span v-if="userData"> {{ userData.email }}, </span>
                </div>
              </td>
              <td class="alin-right">
                <div style="font-size: 10pt; color: #00364f; padding-top: 10px">
                  <strong>BILL TO</strong>
                </div>
              </td>
            </tr>

            <tr>
              <th class="alin-left"><strong class="heading"> </strong></th>
              <th class="alin-right" style="color: #00364f; margin-top: -5px">
                <strong class="heading" style="font-size: 14pt" v-if="invoiceData.client"
                  >{{ invoiceData.client.fname }} {{ invoiceData.client.lname }}</strong
                >
              </th>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="alin-right">
                <div style="font-size: 10pt; color: #00364f">
                  <strong v-if="invoiceData.business">
                    {{ invoiceData.business.name }}</strong
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="alin-right">
                <div style="font-size: 10pt; color: #00364f" v-if="invoiceData.client">
                  {{ invoiceData.client.address }},{{ invoiceData.client.address2 }}
                </div>
              </td>
            </tr>
          </table>

          <hr />

          <table
            class="font"
            style="
              width: 100%;
              color: #00364f;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            <tr>
              <th class="alin-left" style="width: 10%">Invoice Date</th>
              <td class="alin-left">
                {{ dateTime(invoiceData.date) }}
              </td>
              <th class="alin-right">Payments</th>
              <td class="alin-right" style="width: 12%">
                Rs.{{ getAmtDue(invoiceData, "paid") }}
              </td>
            </tr>
            <tr>
              <th class="alin-left"></th>
              <td class="alin-left"></td>
              <th class="alin-right">Balance Due</th>
              <td class="alin-right" style="width: 12%">
                <button class="btn-paid" style="width: 100px">
                  Rs.{{ getAmtDue(invoiceData, "due") }}
                </button>
              </td>
            </tr>
          </table>

          <table
            class="font"
            style="width: 100%; margin-top: 10px; text-transform: capitalize"
          >
            <tr style="background-color: #00364f; font-size: 10pt; color: white">
              <th class="alin-left" style="padding: 5px 5px 5px 5px">Description</th>
              <th class="alin-right" style="padding: 5px 5px 5px 5px">Rate</th>
              <th class="alin-right" style="padding: 5px 5px 5px 5px">GST</th>
              <th class="alin-right" style="padding: 5px 5px 5px 5px">Amount</th>
            </tr>
            <tr style="font-size: 10pt; color: #00364f">
              <td>
                <span v-if="invoiceData.service">{{ invoiceData.service.name }}</span>
                <span v-for="(mod, index) in serviceModules" :key="index"
                  ><span v-if="mod"> ({{ mod.moduleName }})</span></span
                >
              </td>
              <td class="alin-right">Rs.{{ invoiceData.serviceCharge }}</td>
              <td class="alin-right">Rs.{{ invoiceData.serviceChargeGst }}</td>
              <td class="alin-right">
                Rs.{{ invoiceData.serviceCharge + invoiceData.serviceChargeGst }}
              </td>
            </tr>
          </table>

          <div
            style="
              background-color: #f2f2f2;
              padding: 5px 10px 5px 10px;
              border-radius: 10px;
              margin-top: 30px;
            "
          >
            <table
              style="
                width: 100%;
                font-size: 10pt;
                color: #00364f;
                text-transform: capitalize;
              "
              class="font"
            >
              <tr>
                <td class="alin-left">
                  <strong>In words: eight hundred thirty (Rupees)</strong>
                </td>
                <td class="alin-right"><strong>Total</strong></td>
                <td class="alin-right">
                  <strong id="value"
                    >Rs.{{
                      invoiceData.serviceCharge + invoiceData.serviceChargeGst
                    }}</strong
                  >
                </td>
              </tr>
            </table>
          </div>

          <div
            class="font"
            style="
              text-align: center;
              margin-top: 30px;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            This is Computer Generate Invoice No Signature Required
          </div>

          <table
            style="
              width: 100%;
              font-size: 10pt;
              color: #00364f;
              text-transform: capitalize;
            "
          >
            <tr class="font">
              <td style="width: 30%">
                <div class="row">
                  <div class="col text-truncate">In association with: &nbsp;</div>
                  <div class="col" v-if="admin">
                    <img style="width: 60px" :src="logofinder(admin)" />
                  </div>
                </div>
              </td>

              <td class="alin-right">
                <div class="logo-img" v-if="admin">
                  Powerd by: &nbsp;
                  <img style="width: 60px" src="/assets/image/vakilgiri.png" alt="" />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Button to trigger PDF export -->
  <!-- <PdfExportButton /> -->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
// import html2canvas from "html2canvas";
// import html2pdf from "vue3-html2pdf";
// import Banner from '../../../components/retailer/comman/Banner.vue'
// import PdfExportButton from "../../../components/retailer/comman/PdfExportButton.vue";
// import Form from 'vform'
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Packages",
  retailer_id: "",
  components: {},
  data() {
    return {
      userPaymentForm: {
        client_id: "",
        service_id: "",
        client_service_id: "",
        amt: "",
        date: "",
        paymentMode: "Cash",
        txn: "",
        txnRefNo: "",

        checkno: "",
        bank: "",
        ifsc: "",

        branch: "",
        accountno: "",
        notation: "",
        remark: "",
        status: "",
      },
      paidAmt: 0,
      dueAmt: 0,
      paymentRecipts: [],
      admin: {},
      userData: {},
      serviceModules: [],
      inWords: "",
      invoiceData: {},
      totalAmt: null,
      number: null,
      ones: [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ],
      tens: [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ],
      scales: [
        "",
        "",
        "",
        "hundred ",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ],
    };
  },
  methods: {
    validation() {
      var paidAmtuser = this.userPaymentForm.amt + this.paidAmt;
      var totle = this.invoiceData.serviceCharge + this.invoiceData.serviceChargeGst;
      if (paidAmtuser > totle) {
        $("#amountPay").html("Amout Must be less than or equal total Amount");
      } else {
        $("#amountPay").html("");
      }
    },
    getAmtDue(project, type) {
      var paidAmt = 0;
      var dueAmt = 0;
      for (let i = 0; i < this.paymentRecipts.length; i++) {
        paidAmt += this.paymentRecipts[i].amt;
      }
      var amt = project.serviceCharge + project.serviceChargeGst;

      if (type == "paid") {
        this.paidAmt = paidAmt;
        return paidAmt;
      }
      if (type == "due") {
        dueAmt = amt - paidAmt;
        this.dueAmt = dueAmt;
        return dueAmt;
      }
    },
    clientPayServiceAmount() {
      this.$axios
        .get(`retailer/clientpayment?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // this.reciptData = res.data.data;
          this.paymentRecipts = res.data.data.data;
          console.log(this.paymentRecipts);
        });
    },
    generatePDF() {
      alert("hi");


   
 
      // const element = document.getElementById("invoice");
      // html2pdf().from(element).save();
      // console.log();
      // var button;
      // button = document.getElementById("download-button");

      // button.addEventListener('click', generatePDF);

      // window.html2canvas = html2canvas;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.querySelector("#printableArea"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
    },
    logofinder(admin) {
      console.log("====logo=====");
      console.log(admin);
      console.log("=========");
      var img;
      if (admin.logo != null) {
        img = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    countDig(totalAmt) {
      var n = totalAmt;
      var count = 0;
      if (n >= 1) ++count;
      while (n / 10 >= 1) {
        n /= 10;
        ++count;
      }
      return count;
      // this.covertNumberTowor(count)
    },

    atOnse19(amt) {
      return this.ones.at(amt);
    },
    atTese99(amt) {
      var n = 0;
      var res = amt;
      n = parseInt(res / 10);
      amt = parseInt(res % 10);
      var txt1 = this.tens.at(n);
      if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    scales999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 100);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 100);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      // console.log(amt)
      if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo9999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 1000);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo99999(amt) {
      let count = this.countDig(amt);
      // console.log('99999')
      if (count == 4 || count == 5) {
        count = 4;
      }
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n) + " " + this.scales.at(count);
      // console.log(txt1)
      amt = parseInt(res % 1000);
      txt1 = n < 20 ? txt1 : this.atTese99(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        //    console.log(txt1)
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    covertNumberTowor() {
      // console.log('click')
      // console.log(this.totalAmt)
      let count = this.countDig(this.totalAmt);
      // console.log(count)
      if (this.totalAmt < 20) {
        count = 1;
      }
      switch (count) {
        case 1:
          this.inWords = this.atOnse19(this.totalAmt);
          break;
        case 2:
          this.inWords = this.atTese99(this.totalAmt);
          break;
        case 3:
          this.inWords = this.scales999(this.totalAmt);
          break;
        case 4:
          this.inWords = this.valTo9999(this.totalAmt);
          break;
        case 5:
          this.inWords = this.valTo99999(this.totalAmt);
          break;

        default:
          this.inWords = this.totalAmt;
      }
    },
    print() {
      // alert('hello')
      // Get HTML to print from element
      const prtHtml = document.getElementById("printableArea").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
              <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    lodaInvoices() {
      this.$axios
        .get(`retailer/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.invoiceData = res.data.data;
          if (res.data.data.admin) {
            this.admin = res.data.data.admin;
          }

          this.totalAmt = this.invoiceData.serviceCharge;
          // console.log('---------------------------------------------')
          // console.log(this.invoiceData.serviceModules)
          var mod = JSON.parse(this.invoiceData.serviceModules);
          // console.log(JSON.parse(this.invoiceData.serviceModules));

          this.$axios
            .post(
              `/retailer/servicemodule`,
              { serviceModulesIds: mod },
              { headers: { Authorization: "Bearer " + localStorage.accessToken } }
            )
            .then((res) => {
              // console.log(res.data)
              this.serviceModules = res.data.data;
            });
          // console.log('---------------------------------------------')

          // mod = unserialize(this.invoiceData.serviceModules);

          // console.log(mod);
          // document.write('<br />'+"Unserialize the above data and go back to original array : " +'<br />');

          this.countDig();
          this.covertNumberTowor();
        });
    },
    loadUserApi() {
      this.$axios
        .post(
          `retailer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },
    dateTime(value) {
      value;
      // console.log("========");
      // console.log(moment().format(value));

      return moment(value).format("DD-MM-YYYY");
    },
    clientPayServiceAmountPay() {
      this.userPaymentForm.client_id = this.invoiceData.client_id;
      this.userPaymentForm.client_service_id = this.invoiceData.id;
      this.userPaymentForm.service_id = this.invoiceData.service_id;
      this.loading = true;
      this.$axios
        .post(`retailer/clientpayment`, this.userPaymentForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          this.clientPayServiceAmount();
          this.userPaymentForm = {};

          toast.success("Save Successfully", {
            autoClose: 1000,
          });
          $("#paymentReciptPay").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error.data.message;
          this.msgError = error.response.data;
          console.log(error.response.data);
        });
    },
    valuenullPayment() {
      this.userPaymentForm = {};
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.lodaInvoices();
      this.loadUserApi();
      this.clientPayServiceAmount();
    }
  },
};
</script>

<style scoped>
@media print {
  /* body {
          -webkit-print-color-adjust: exact;
      } */

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .font {
    font-family: "Poppins", sans-serif;
  }
  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666666666666%;
  }

  .col-sm-10 {
    width: 83.33333333333334%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666666666666%;
  }

  .col-sm-7 {
    width: 58.333333333333336%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666666666667%;
  }

  .col-sm-4 {
    width: 33.33333333333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.666666666666664%;
  }

  .col-sm-1 {
    width: 8.333333333333332%;
  }
}

.disp-flex {
  display: flex;
}

.alin-left {
  text-align: left;
}

.alin-right {
  text-align: end;
}

.heading {
  color: #00364f;
  text-transform: uppercase;
  font-size: 16pt;
  font-family: "Poppins", sans-serif;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btn-paid {
  color: white;
  background-color: green;
  border-radius: 20px;
  border: none;
  font-size: 8pt;
  width: 70px;
  box-shadow: 0px 0px 5px 0px gray;
  padding: 3px 3px 3px 3px;
}
</style>
